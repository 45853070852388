import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Background } from './../layouts/background/background';
import { Cursor } from './../cursor/cursor';
import { Header } from './../header/header';
import { Footer } from './../footer/footer';

export type LayoutType = {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutType) => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width" />
        <title>
          Clark Fennell's Portfolio - Frontend Developer | Technical Artist | Game Developer | UX/UI Designer
        </title>
      </Helmet>
      <Background>
        <main>
          <Cursor />
          <Header />
          {children}
          <Footer />
        </main>
      </Background>
    </HelmetProvider>
  );
};
