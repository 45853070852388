import { useContext } from 'react';
import TextLoop from 'react-text-loop';
import { CursorContext } from './../../../cursor/context/cursorContext';
import './styles.scss';

export type AltHeroTypes = {
  title?: string;
  subTitle?: string[];
  wordList?: string[];
  link_href?: string;
  link_text?: string;
  link_alt?: string;
  setTop?: boolean;
}

export const AltHero = ({ title, subTitle, wordList, link_href, link_text, link_alt, setTop }: AltHeroTypes) => {
  const { setType } = useContext(CursorContext);

  return (
    <div className={setTop ? 'hero_wrapper hidden' : 'hero_wrapper'}>
      <h1 className="hero_title btn btn_bold">{title}</h1>
      <TextLoop interval={4000} noWrap={true} className='text-loop'>
        {subTitle && subTitle.map((sub) => <div className="hero_sub_title hero_sub_title_first">{sub}</div>)}
      </TextLoop>
      <TextLoop delay={2000} interval={4000} noWrap={true} className='text-loop'>
        {wordList && wordList.map((word) => <div className="hero_sub_title hero_sub_title_last">{word}</div>)}
      </TextLoop>
      {link_href !== null && typeof link_href !== 'undefined' ? (
        <a
          href={link_href}
          className="btn btn_bold btn_hero"
          data-alt={link_alt}
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => setType('link')}
          onMouseLeave={() => setType('default')}
        >
          {link_text}
        </a>
      ) : null}
    </div>
  );
};
