import { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect'

export type HomePyramidTypes = {
  setTop?: boolean;
}

interface DeviceOrientationEventiOS extends DeviceOrientationEvent {
  requestPermission?: () => Promise<'granted' | 'denied'>;
}

export const HomePyramid = ({ setTop }: HomePyramidTypes) => {
  const [isMoving, setIsMoving] = useState(setTop);
  const [isPermission, setIsPermission] = useState(false);
  const pyramidContainer = useRef<any>(null);
  const eye = useRef<any>(null);
  const pupil = useRef<any>(null);
  const triangle = useRef<any>(null);
  const triangleShadowOne = useRef<any>(null);
  const triangleShadowTwo = useRef<any>(null);
  const triangleShadowThree = useRef<any>(null);
  const triangleShadowFour = useRef<any>(null);

  const DeviceOrientation = (event: any) => {
    const beta = event.beta;
    const gamma = event.gamma;
    if (triangle.current.style) {
      triangle.current.style.transform = `translate3d(${gamma * 2}px, ${(beta - 90) * 2}px, 0)`;
      triangle.current.style.transition = `none`;
    }
    if (triangleShadowOne.current.style) {
      triangleShadowOne.current.style.transform = `translate3d(${gamma * 3}px, ${(beta - 90) * 3}px, 0)`;
      triangleShadowOne.current.style.transition = `none`;
    }
    if (triangleShadowTwo.current.style) {
      triangleShadowTwo.current.style.transform = `translate3d(${gamma * 4}px, ${(beta - 90) * 4}px, 0)`;
      triangleShadowTwo.current.style.transition = `none`;
    }
    if (triangleShadowThree.current.style) {
      triangleShadowThree.current.style.transform = `translate3d(${gamma * 5}px, ${(beta - 90) * 5}px, 0)`;
      triangleShadowThree.current.style.transition = `none`;
    }
    if (triangleShadowFour.current.style) {
      triangleShadowFour.current.style.transform = `translate3d(${gamma * 6}px, ${(beta - 90) * 6}px, 0)`;
      triangleShadowFour.current.style.transition = `none`;
    }
    if (eye.current.style) {
      eye.current.style.transform = `translate3d(${-gamma * 0.8}px, ${-(beta - 90) * 0.8}px, 0)`;
      eye.current.style.transition = `none`;
    }
    if (pupil.current.style) {
      pupil.current.style.transform = `translate3d(${-gamma * 0.8}px, ${-(beta - 90) * 0.8}px, 0)`;
      pupil.current.style.transition = `none`;
    }
  };

  const MouseOrientation = (event: any) => {
    const { clientX, clientY } = event;
    const mouseX = window.innerWidth / 2 - clientX;
    const mouseY = window.innerHeight / 2 - clientY;
    if (triangle.current.style) {
      triangle.current.style.transform = `translate3d(${mouseX * 0.1}px, ${mouseY * 0.1}px, 0)`;
      triangle.current.style.transition = `none`;
    }
    if (triangleShadowOne.current.style) {
      triangleShadowOne.current.style.transform = `translate3d(${mouseX * 0.15}px, ${mouseY * 0.15}px, 0)`;
      triangleShadowOne.current.style.transition = `none`;
    }
    if (triangleShadowTwo.current.style) {
      triangleShadowTwo.current.style.transform = `translate3d(${mouseX * 0.2}px, ${mouseY * 0.2}px, 0)`;
      triangleShadowTwo.current.style.transition = `none`;
    }
    if (triangleShadowThree.current.style) {
      triangleShadowThree.current.style.transform = `translate3d(${mouseX * 0.25}px, ${mouseY * 0.25}px, 0)`;
      triangleShadowThree.current.style.transition = `none`;
    }
    if (triangleShadowFour.current.style) {
      triangleShadowFour.current.style.transform = `translate3d(${mouseX * 0.3}px, ${mouseY * 0.3}px, 0)`;
      triangleShadowFour.current.style.transition = `none`;
    }
    if (eye.current.style) {
      eye.current.style.transform = `translate3d(${-mouseX * 0.05}px, ${-mouseY * 0.05}px, 0)`;
      eye.current.style.transition = `none`;
    }
    if (pupil.current.style) {
      pupil.current.style.transform = `translate3d(${-mouseX * 0.1}px, ${-mouseY * 0.1}px, 0)`;
      pupil.current.style.transition = `none`;
    }
  };

  const ClosedDeviceOrientation = (event: any) => {
    const beta = event.beta;
    const gamma = event.gamma;
    if (triangle.current.style) {
      triangle.current.style.transform = `translate3d(0px, 0px, 0)`;
      triangle.current.style.transition = `transform .4s ease-in-out`;
    }
    if (triangleShadowOne.current.style) {
      triangleShadowOne.current.style.transform = `translate3d(125px, 0px, 0)`;
      triangleShadowOne.current.style.transition = `transform .4s ease-in-out`;
    }
    if (triangleShadowTwo.current.style) {
      triangleShadowTwo.current.style.transform = `translate3d(-125px, 0px, 0)`;
      triangleShadowTwo.current.style.transition = `transform .4s ease-in-out`;
    }
    if (triangleShadowThree.current.style) {
      triangleShadowThree.current.style.transform = `translate3d(250px, 0px, 0)`;
      triangleShadowThree.current.style.transition = `transform .4s ease-in-out`;
    }
    if (triangleShadowFour.current.style) {
      triangleShadowFour.current.style.transform = `translate3d(-250px, 0px, 0)`;
      triangleShadowFour.current.style.transition = `transform .4s ease-in-out`;
    }
    if (eye.current.style) {
      eye.current.style.transform = `translate3d(${-gamma * 1.5}px, ${-(beta - 90) * 0.8}px, 0)`;
      eye.current.style.transition = `none`;
    }
    if (pupil.current.style) {
      pupil.current.style.transform = `translate3d(${-gamma * 1.5}px, ${-(beta - 90) * 0.8}px, 0)`;
      pupil.current.style.transition = `none`;
    }
  };

  const ClosedMouseOrientation = (event: any) => {
    const { clientX, clientY } = event;
    const mouseX = window.innerWidth / 2 - clientX;
    const mouseY = window.innerHeight / 6 - clientY;
    if (triangle.current.style) {
      triangle.current.style.transform = `translate3d(0px, 0px, 0)`;
      triangle.current.style.transition = `transform .4s ease-in-out`;
    }
    if (triangleShadowOne.current.style) {
      triangleShadowOne.current.style.transform = `translate3d(125px, 0px, 0)`;
      triangleShadowOne.current.style.transition = `transform .4s ease-in-out`;
    }
    if (triangleShadowTwo.current.style) {
      triangleShadowTwo.current.style.transform = `translate3d(-125px, 0px, 0)`;
      triangleShadowTwo.current.style.transition = `transform .4s ease-in-out`;
    }
    if (triangleShadowThree.current.style) {
      triangleShadowThree.current.style.transform = `translate3d(250px, 0px, 0)`;
      triangleShadowThree.current.style.transition = `transform .4s ease-in-out`;
    }
    if (triangleShadowFour.current.style) {
      triangleShadowFour.current.style.transform = `translate3d(-250px, 0px, 0)`;
      triangleShadowFour.current.style.transition = `transform .4s ease-in-out`;
    }
    if (eye.current.style) {
      eye.current.style.transform = `translate3d(${-mouseX * 0.1}px, ${-mouseY * 0.1}px, 0)`;
      eye.current.style.transition = `none`;
    }
    if (pupil.current.style) {
      pupil.current.style.transform = `translate3d(${-mouseX * 0.1}px, ${-mouseY * 0.1}px, 0)`;
      pupil.current.style.transition = `none`;
    }
  };

  const getiOSDeviceOrientationPermission = async () => {
    const requestPermission = (DeviceOrientationEvent as unknown as DeviceOrientationEventiOS).requestPermission;
    const iOS = typeof requestPermission === 'function';
    if (iOS) {
        const response = await requestPermission();
        setIsPermission(true);
        return response;
    }
  }

  useEffect(() => {
    const noHover = window.matchMedia('(hover: none)');
    let handler: [string, (event: any) => void, boolean];

    getiOSDeviceOrientationPermission();    

    if (!isMoving) {
      handler = noHover.matches || isMobile || isPermission
        ? ['deviceorientation', (event: any) => DeviceOrientation(event), true]
        : ['mousemove', (event: any) => MouseOrientation(event), true];
    } else {
      handler = noHover.matches || isMobile || isPermission
        ? ['deviceorientation', (event: any) => ClosedDeviceOrientation(event), true]
        : ['mousemove', (event: any) => ClosedMouseOrientation(event), true];
    }
    if (handler) window.addEventListener(...handler);
    return () => {
      if (handler) window.removeEventListener(...handler);
    };

  }, [isMoving, isPermission]);

  return (
    <div className={!isMoving ? 'hero_wrapper home_page' : 'hero_wrapper home_page active'}>
      <canvas className="hero_background"></canvas>
      <svg
        className={!isMoving ? 'hero_svg active' : 'hero_svg'}
        id="pyramidContainer"
        onClick={() => setIsMoving(!isMoving)}
        ref={pyramidContainer}
        viewBox="0 0 1042.15 901.31"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g ref={triangleShadowFour} id="Shadow_4">
          <polygon points="521.15 0 1041.12 900.62 1.18 900.62 521.15 0" fill="#59190a" />
        </g>
        <g ref={triangleShadowThree} id="Shadow_3">
          <polygon points="521.15 0 1041.12 900.62 1.18 900.62 521.15 0" fill="#681d0b" />
        </g>
        <span>Let me show off</span>
        <g ref={triangleShadowTwo} id="Shadow_2">
          <polygon points="521.15 0 1041.12 900.62 1.18 900.62 521.15 0" fill="#76210d" />
        </g>
        <g ref={triangleShadowOne} id="Shadow_1">
          <polygon points="521.15 0 1041.12 900.62 1.18 900.62 521.15 0" fill="#85250e" />
        </g>
        <g ref={triangle} id="Upper">
          <polygon points="521.07 5.93 1036.95 897.95 5.19 897.95 521.07 5.93" fill="#121616" />
          <g ref={eye} id="Inner_Eye">
            <path
              d="M324.24,567.66c5.17-102.51,96.69-182.58,204.39-178.79s190.79,90,185.67,192.47S617.61,763.92,509.94,760.13,319.09,670.13,324.24,567.66Z"
              fill="#f7f3e3"
            />
            <path
              d="M512.69,720.16C571.53,722.38,624.43,694.5,654,651a143,143,0,0,0,25-74.33c3.92-82.6-64.78-152-152.84-152.29-1.89,0-3.79,0-5.69.17-85.57,2-156.54,59.19-160.36,140C356.34,647.06,424.59,716.87,512.69,720.16Z"
              fill="#942910"
            />
            <path
              ref={pupil}
              d="M439.4,562.48c1.88-42.71,39.26-75.83,83.55-74s78.6,37.89,76.78,80.6-39.23,75.78-83.53,74S437.56,605.15,439.4,562.48Z"
              fill="#f7f3e3"
            />
          </g>
          <g id="Outer">
            <g id="Layer_8" data-name="Layer 8">
              <path
                d="M909.64,676.05c-20.29-5.84-34.33-24.84-35.31-46.65a39.35,39.35,0,0,1,1.45-11.91l-6.52-11.28a48.28,48.28,0,0,1-16.88,3.32q-.3,1.5-.69,3c-2.75,10.65-8.49,19-14.93,27,1.35,4.53,2.74,9.06,3.73,13.68,2.83,13.28,1.41,25.45-4.89,37.37a39.92,39.92,0,0,1-8.59,10.9,34.53,34.53,0,0,1-1.63,3.56,43.58,43.58,0,0,1-25.63,25.64,36.52,36.52,0,0,1-18.69,4.18H776c-.52,0-1,0-1.55-.05-18.38,8.39-37.22,14.53-56.72,19.78-17.31,4.65-34.66,9.2-51.28,16-18,7.57-36.09,14.77-54.87,20.28-21.1,6.18-42.51,9.88-64.22,12.86l7.35-1c-29.26,4.29-58.4,7-88,4.37a380.79,380.79,0,0,1-73.3-13.39c-24.59-7.2-48.3-17-71.77-27.22-22.55-9.8-45-20.16-66.81-31.51-5.28-2.75-10.88-5.37-15.78-8.76a198.24,198.24,0,0,1-19.38-15.45c-5.93-5.31-11.63-10.86-17.66-16-.91-.61-1.84-1.19-2.77-1.75q-1-.22-2-.39c-12.19-1.93-22.58-12.36-29-22.29a38.19,38.19,0,0,1-4.37-9.45,51.59,51.59,0,0,1-3.62-5.26l-.89-.79a39,39,0,0,1-7-8.88L6.42,898.31H1038.18Z"
                fill="#121616"
              />
              <path
                d="M386.68,384.64c29.34-7.54,59.73-9.71,89.08-17.2a48.36,48.36,0,0,1,41.31,8c3.53-.2,7.06-.37,10.59-.47A271.13,271.13,0,0,1,612.6,386a672.06,672.06,0,0,1,67.88,24.2c25.39,10.64,51.9,21.52,74.9,36.89,14.29,9.56,25.88,20.89,34.77,35.72,4.58,7.63,8,15.91,11.27,24.21a92.89,92.89,0,0,1,9,8.75c1.92-.45,3.87-.84,5.86-1.2L522.3,6.29,279.92,425.39c13.53-7,27.76-12.8,41.83-18.36C342.86,398.68,364.68,390.3,386.68,384.64Z"
                fill="#121616"
              />
            </g>
            <g id="Outer_Eye">
              <path
                d="M897,650.73l-.09-.21c-.24-.25-.5-.49-.74-.75a40.94,40.94,0,0,1-3.16-3.31l-.63-.49c-.44-.44-.84-.89-1.26-1.34-12.1,5.69-26.24,12.86-40.52,21-10.73-20.25,1.61-39.18,15.88-52.95-.29-.34-.57-.68-.84-1-2.77-3.67-5.34-7.45-7.76-11.32-12,14.51-23.39,33.44-23.27,51.88A40,40,0,0,0,839.8,672,349.1,349.1,0,0,0,806,695c-38.51,30.31-98.49,49.84-154.31,67.54-128.35,40.81-277.78,38.62-420.24-62.3a912.73,912.73,0,0,1-74-58.49c-.4.64-.83,1.28-1.27,1.91a18.13,18.13,0,0,1-8.15,6.56,17.1,17.1,0,0,1-2.69,4.64C221.46,735.57,319.93,792.62,415,813c156.37,33.54,336.69-43.35,389.4-83.25,33.26-25.17,74.18-38.95,105.15-51-4.25-6.87-7.24-14.42-10.2-21.92C898.57,654.81,897.77,652.77,897,650.73Z"
                fill="#f7f3e3"
              />
              <path
                d="M794.07,481.87c-1.11-3-2.28-6.08-3.53-9.08-.2-.45-1.06-2.44-1.1-2.52q-1.05-2.34-2.15-4.65a266.38,266.38,0,0,0-21.41-36c-17.51-25.44-25.7-56.11-44.57-80.72a15.94,15.94,0,0,1-9.76-11.6c-57.89-35.41-121-56.68-182.82-56.6-85,.12-142.68,16.2-196.06,46.19-3.38,22.93-17.66,44.18-29.49,63.34a16.23,16.23,0,0,1-2.78,3.38,78.33,78.33,0,0,1-7.91,20.63c-3.12,5.73-9.48,8.15-15.53,7.36-10.09,20.07-20.28,40.1-31.45,59.58,14.56-9.83,30.72-19.63,48.82-29.53,86.74-47.49,193-65.7,289.06-52.22C672.17,411.88,740.52,446.3,798.81,496,797.33,491.24,795.78,486.53,794.07,481.87Z"
                fill="#f7f3e3"
              />
              <path
                d="M879.86,624.57l-.33-2.47c-.66-1-1.33-1.94-2-2.92a18,18,0,0,1-11.11-6.47c-14.27,13.77-26.61,32.7-15.88,52.95,14.28-8.17,28.42-15.34,40.52-21A48.5,48.5,0,0,1,879.86,624.57Z"
                fill="#942910"
              />
            </g>
            <path d="M521.07,18.29l505.48,874H15.6l505.47-874m0-18L0,901.31H1042.15L521.07.31Z" fill="#f7f3e3" />
          </g>
        </g>
      </svg>
    </div>
  );
};
