import { createContext, useState, ReactNode } from 'react';

interface AppType {
  firstLoad: boolean;
  updateFirstLoad:(type: boolean) => void;
}

interface Props {
  children?: ReactNode
}

export const AppContext = createContext<AppType>({
  firstLoad: false,
  updateFirstLoad: () => {},
});

export const AppProvider = ({ children }: Props) => {
  const [firstLoad, setFirstLoad] = useState(false);

  const updateFirstLoad = (value: boolean) => {
    setFirstLoad(value);
  };

  return <AppContext.Provider value={{ firstLoad, updateFirstLoad }}>{children}</AppContext.Provider>;
};
