import React, { useEffect, useRef, useContext } from 'react';
import { CursorContext } from './context/cursorContext';
import './styles.scss';

export const Cursor = () => {
  const cursorMain = useRef<any>(null);
  const cursorSecondary = useRef<any>(null);
  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1
  });
  
  const { type } = useContext(CursorContext);
  useEffect(() => {
    document.addEventListener('mousemove', (event) => {
      const { clientX, clientY } = event;
      const mouseX = clientX;
      const mouseY = clientY;
      
      positionRef.current.mouseX = (mouseX - cursorSecondary?.current?.clientWidth / 2) || mouseX;
      positionRef.current.mouseY = (mouseY - cursorSecondary?.current?.clientHeight / 2) || mouseY;

      if (cursorMain?.current?.style !== null) {
        cursorMain.current.style.transform = `translate3d(${mouseX - cursorMain.current.clientWidth / 2}px, ${mouseY - cursorMain.current.clientHeight / 2}px, 0`;
      }
    })

    return () => {};
  }, []);

  useEffect(() => {
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse);
      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;
      
      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
        if (Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) < 0.1) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }
      if (cursorSecondary.current.style !== null) {
        cursorSecondary.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
      }
    };
    followMouse();
  }, []);

  return (
    <div className={`cursor_wrapper ${type}`}>
      <div className="cursor cursor_main" ref={cursorMain}></div>
      <div className="cursor cursor_secondary" ref={cursorSecondary}></div>
    </div>
  )
}
