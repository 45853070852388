import './styles.scss';
import { HomePyramid } from './heroTypes/homePyramid/homePyramid';
import { AltHero } from './heroTypes/altHero/altHero';

export type HeroTypes = {
  type: string;
  title?: string;
  subTitle?: string[];
  wordList?: string[];
  link_href?: string;
  link_text?: string;
  link_alt?: string;
  setTop?: boolean;
}

export const Hero = ({ type, title, subTitle, wordList, link_href, link_text, link_alt, setTop }: HeroTypes) => {
  const HeroSelect = () => {
    switch (type) {
      case 'home':
        return <HomePyramid setTop={setTop} />;
      default:
        return (
          <AltHero
            title={title}
            subTitle={subTitle}
            wordList={wordList}
            link_href={link_href}
            link_text={link_text}
            link_alt={link_alt}
            setTop={setTop}
          />
        );
    }
  };

  return <div className={`hero hero_container hero_${type} `}>{HeroSelect()}</div>;
};
