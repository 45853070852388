import { Routes, Route } from 'react-router-dom';
import CursorManager from './components/cursor/context/cursorContextManager';
import { Layout } from './components/layouts/layout';
import Home from './pages/home/home';
import SkillSet from './pages/skill-set/skill-set';
import Contact from './pages/contact/contact';
import Error from './404';
import { AppProvider } from './components/layouts/AppProvider/AppProvider';

const App = () => {
  return (
    <CursorManager>
      <AppProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/skill-set" element={<SkillSet />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Layout>
      </AppProvider>
    </CursorManager>
  );
};

export default App;
