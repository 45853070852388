import { ReactNode, useState } from 'react';
import { CursorContext, CursorStyleType } from "./cursorContext";

interface Props {
  children?: ReactNode
}

const CursorManager = ({ children }: Props) => {
  const [type, setType] = useState<CursorStyleType>('default');
  return (
    <CursorContext.Provider value={{type, setType}}>
      { children }
    </CursorContext.Provider>
  )
}

export default CursorManager;