import { createContext } from 'react';

export type CursorStyleType = 'default' | 'hamburger' | 'link' | 'link_nav';

export type CursorType = {
  type: CursorStyleType;
  setType:(type: CursorStyleType) => void;
}

export const CursorContext = createContext<CursorType>({
  type: 'default',
  setType:() => {}
})
