import { useContext, useCallback }  from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from './../layouts/AppProvider/AppProvider';
import { CursorContext } from './../cursor/context/cursorContext';

import './styles.scss';

export const Footer = () => {
  const { setType } = useContext(CursorContext);
  const { updateFirstLoad } = useContext(AppContext);

  const toggleOnClick = useCallback(
    () => {
      updateFirstLoad(true);
    },
    [updateFirstLoad]
  );

  return (
    <footer className="footer">
      <div className="footer-logo">
        <Link 
          to="/"
          className="btn btn_bold"
          onClick={toggleOnClick}
          onMouseEnter={() => setType('link')}
          onMouseLeave={() => setType('default')}
        >my name is clark fennell</Link>
      </div>
      <div className="footer-nav">
        <ul>
          <Link
            to="/"
            className="btn btn_primary"
            onClick={toggleOnClick}
            onMouseEnter={() => setType('link')}
            onMouseLeave={() => setType('default')}
          ><li>meet clark</li></Link>
          <Link 
            to="/skill-set"
            className="btn btn_primary"
            onClick={toggleOnClick}
            onMouseEnter={() => setType('link')}
            onMouseLeave={() => setType('default')}
          ><li>time to show off</li></Link>
          <Link
            className="btn btn_primary"
            to='/CV-2025.pdf'
            target="_blank"
            onClick={toggleOnClick}
            onMouseEnter={() => setType('link')}
            onMouseLeave={() => setType('default')}
          >
            <li>résumé</li>
          </Link>
          <Link 
            to="/contact"
            className="btn btn_primary"
            onClick={toggleOnClick}
            onMouseEnter={() => setType('link')}
            onMouseLeave={() => setType('default')}
          ><li>get in touch</li></Link>
        </ul>
      </div>
    </footer>
  )
}
