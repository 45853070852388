import { useContext, useEffect, useState } from 'react';
import { register } from 'swiper/element/bundle';

import { CursorContext } from './../../components/cursor/context/cursorContext';
import { Hero } from './../../components/hero/hero';

import "swiper/react"
import "swiper/css/effect-cards";

register();

const SkillSet = () => {
  const { setType } = useContext(CursorContext);
  const [skills, setSkills] = useState<any>([]);
  const [hasRunOnce, setHasRunOnce] = useState<boolean>(false);

  const fetchData = async (api: any) => {
    const response = await fetch(api);
    if (!response.ok) {
      throw new Error('Resume data could not be fetched!');
    } else {
      const data = await response.json();
      return data;
    }
  };

  const shuffle = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  useEffect(() => {
    if (!hasRunOnce) {
      fetchData(`./data/data_resume.json`)
        .then((res) => {
          setSkills(shuffle(res.skills));
        })
        .catch((e) => {
          console.log(e.message);
        })
        .finally(() => setHasRunOnce(true))
    }
  });

  return (
    <>
      <Hero type="home" setTop={true} />
      <Hero type="altHero" title={`An expert in modern`} />
      {skills && (
        <swiper-container grabCursor={true}  effect="cards" className="block_content block_container mySwiper">
          {skills.map((skill: any, index: number) => (
            <swiper-slide className="block_container_item" key={index} onMouseEnter={() => setType('link_nav')} onMouseLeave={() => setType('default')}>
              <img className="icon" src={skill.icon} alt={skill.name} />
              <h2>{skill.name}</h2>
            </swiper-slide>
          ))}
        </swiper-container>
      )}
    </>
  );
};

export default SkillSet;
