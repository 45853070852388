import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGitAlt } from '@fortawesome/free-brands-svg-icons'

import { Hero } from './../../components/hero/hero';
import { CursorContext } from './../../components/cursor/context/cursorContext';

const Contact = () => {
  const { setType } = useContext(CursorContext);
  return (
    <>
      <Hero type="home" setTop={true} />
      <Hero type="altHero" title={`get in touch`} />
      <div className="block_content block_flex">
        <a
          className="block_flex_item btn btn_bold btn_hero"
          href="tel:+447724278685"
          data-alt="Call me on 07724278685"
          onMouseEnter={() => setType('link')}
          onMouseLeave={() => setType('default')}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faPhoneVolume} />
          <p>Call</p>
        </a>
        <a
          className="block_flex_item btn btn_bold btn_hero"
          href="mailto:clarkfennell@mac.com"
          data-alt="Email @ clarkfennell@mac.com"
          onMouseEnter={() => setType('link')}
          onMouseLeave={() => setType('default')}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faEnvelopeOpenText} />
          <p>Email</p>
        </a>
        <a
          className="block_flex_item btn btn_bold btn_hero"
          href="https://www.linkedin.com/in/clark-fennell-5b308672/"
          data-alt="LinkedIn"
          onMouseEnter={() => setType('link')}
          onMouseLeave={() => setType('default')}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
          <p>LinkedIn</p>
        </a>
        <a
          className="block_flex_item btn btn_bold btn_hero"
          href="https://github.com/clarkfennell"
          data-alt="Github"
          onMouseEnter={() => setType('link')}
          onMouseLeave={() => setType('default')}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faGitAlt} />
          <p>Github</p>
        </a>
      </div>
    </>
  );
};

export default Contact;
