import { useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Hero } from "./components/hero/hero";
import { CursorContext } from './components/cursor/context/cursorContext';
import { AppContext } from './components/layouts/AppProvider/AppProvider';

const Error = () => {
  const { setType } = useContext(CursorContext);
  const { updateFirstLoad } = useContext(AppContext);

  const toggleOnClick = useCallback(
    () => {
      updateFirstLoad(true);
    },
    [updateFirstLoad]
  );

  return (
    <>
      <Hero type="home" setTop={true} />
      <Hero
        type="altHero"
        title="you seem to be lost in the infinite void"
      />
      <div className="block_content">
        <Link
          className="btn btn_bold btn_big"
          to='/'
          onClick={toggleOnClick}
          onMouseEnter={() => setType('link_nav')}
          onMouseLeave={() => setType('default')}
        >let me guide you back home</Link>
        <Link 
          className="btn btn_bold btn_big"
          to='/contact'
          onClick={toggleOnClick}
          onMouseEnter={() => setType('link_nav')}
          onMouseLeave={() => setType('default')}
        >get in touch</Link>
      </div>
    </>
  )
}

export default Error;