import { ReactNode } from 'react';
import './styles.scss';

export type ContainerType = {
  children: ReactNode;
}

export const Container = ({children}: ContainerType) => {
  return <div className="container">{children}</div>;
};
