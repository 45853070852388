import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { Hero } from './../../components/hero/hero';
import { Container } from './../../components/container/container';
import { CursorContext } from './../../components/cursor/context/cursorContext';
import { AppContext } from './../../components/layouts/AppProvider/AppProvider';

const Home = () => {
  const [appear, setAppear] = useState(false);
  const { firstLoad } = useContext(AppContext);
  const { setType } = useContext(CursorContext);

  const handleClick = () => {
    setAppear(true)
    console.log('clicked');
  }

  return (
    <>
      <div className={appear || firstLoad ? "wrapper-home active" : "wrapper-home"} onClick={handleClick}>
        <Hero type="home" setTop={firstLoad} />
      </div>
      <div className={appear || firstLoad ? "wrapper active" : "wrapper"}>
        <Hero
          type="altHero"
          title="meet clark"
          subTitle={['a neurodivergent', 'an experienced', 'a passionate', 'an accomplished']}
          wordList={['frontend dev', 'tech artist', 'game dev', 'ux/ui designer']}
          setTop={firstLoad}
        />
        <Container>
          <p>
            I'm a web developer with a passion for front-end languages. I've been working in the industry for over 8 years, and I've developed a strong expertise in HTML, CSS, JavaScript, React, and Vue. I'm also proficient in PHP and Typescript.
            <br/>
            I'm a creative and detail-oriented developer who loves to bring innovative UX designs to life. I'm also a great team player who is always willing to help out my colleagues.
            <br/>
            I'm looking for a challenging and rewarding position where I can use my skills to make a real impact. I'm also eager to learn new things and grow as a developer.
          </p>

          <h4>Here are some of my skills and accomplishments:</h4>
          <ul>
            <Link onMouseEnter={() => setType('link')} onMouseLeave={() => setType('default')} target="_blank" className="btn btn_primary" to="https://time.com/themarchdigitalexhibit/"><li>Developed the front-end for the Time Magazine "The March" digital exhibit</li></Link>
            <Link onMouseEnter={() => setType('link')} onMouseLeave={() => setType('default')} target="_blank" className="btn btn_primary" to="https://www.egorestaurants.co.uk"><li>Led the development of the Ego Restaurants website, a multi-location chain</li></Link>
            <Link onMouseEnter={() => setType('link')} onMouseLeave={() => setType('default')} target="_blank" className="btn btn_primary" to="https://flauntdigital.com"><li>Built the Flaunt Digital website, a successful B-corp digital agency</li></Link>
            <Link onMouseEnter={() => setType('link')} onMouseLeave={() => setType('default')} target="_blank" className="btn btn_primary" to="https://www.bambukuibiza.com"><li>Developed the Bambuku Ibiza website, which has been praised for its beautiful imagery and user-friendly interface</li></Link>
            <Link onMouseEnter={() => setType('link')} onMouseLeave={() => setType('default')} target="_blank" className="btn btn_primary" to="https://www.hubscape.co.uk"><li>Currently working on a new app and web app for the environmental industry</li></Link>
          </ul>

          <h5>
            I'm also a freelance developer, and I'm available for contract work. If you have a project that you need help with, please don't hesitate to contact me.
            <br/>
            <br/>
            I'm excited to learn more about your company and see how I can contribute.
          </h5>
          <br/>
          <br/>
        </Container>
      </div>
    </>
  );
};

export default Home;
